.footerContainer{
    bottom: 0;
    left: 0;
    right: 0;
    height: 65px;
    background-color: #fff;
    padding: 10px;
    z-index: 2;
}

.footerList{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-family: Barlow-Bold;
    list-style: none;
    margin-top: 3px;
}

.footerList > li > a{
    font-size: 14px;
}

.linkStyleFooter{
    text-decoration: none;
    color: #000;
    font-size: 18px;
}

.iconFooter{
    align-self: center;
    margin-right: 4px;
}

.logoFooterContainer{
    display: flex;
    justify-content: center;
}

.logoFooterContainer > img{
    position: absolute;
    width: 150px;
    margin-top: 8px;
}

@media only screen and (max-width: 540px){
    .footerContainer{
        width: 118%;
        height: 65px;
    }
}
@font-face {
  font-family: Barlow-Bold;
  src: url('./content/fonts/Barlow-Bold.ttf');
}

* {
  margin: 0;
  padding: 0;
  font-family: Barlow-Bold;
}

html, body, #root{
  background-color: #f2f2f2;
  height: 100vh;
  width: 100%;
  scroll-behavior: smooth;
}
.contentContainer{
    justify-content: space-around;
    text-align: center;
    align-items: center;
    display: flex;
}

.content{
    background-color: #f2f2f2;
    border-bottom: 1px solid #3333;
    padding: 2rem;
    width: 100%;
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.contentClientLogo{
    background-color: #f2f2f2;
    border-bottom: 1px solid #3333;
    padding: 2rem;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.contentPartner{
    background-color: #f2f2f2;
    border-bottom: 1px solid #3333;
    padding: 2rem;
    width: 100%;
    height: 115vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    overflow: scroll;
}

.contentHght{
    height: 70vh;
}

.contentVideoHght{
    height: 85vh;
}

.contentMidleLine{
    border-right: 1px solid #3333;
}

.background{
    background-image: url('../../content/images/mobile.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #f2f2f2;
    width: 100%;
    min-width: 40vw;
    overflow: hidden;
}

.background2{
    background-image: url('../../content/images/mobil3.jpg');
}

.background3{
    background-image: url('../../content/images/mobile2.jpg');
}

.Title{
    font-family: Barlow-Bold;
    font-weight: bold;
    font-size: 28px;
    padding: 1rem;
}

.bodyText{
    font-family: sans-serif;
    font-style: italic;
    margin-top: 15px;
    font-size: 16px;
}

.bodyText > h4{
    margin-top: 15px;
    font-family: Barlow-Bold;
    font-style: italic;
}

.button{
    width: 100%;
    max-width: 140px;
    height: 40px;
    justify-content: center;
    text-align: center;
    align-self: center;
    margin: 15px;
    border-radius: 3px;
    border: none;
    background-color: #0097e6;
    cursor: pointer;
    color: #f2f2f2;
    font-family: Barlow-Bold;
    font-weight: bold;
    transition: 1s;

}

.button:hover{
    background-color: #00a8ff;
}

.logosClientContainer{
    display: grid;
    grid-template-columns: auto auto auto auto;
    margin-top: 35px;
    justify-items: center;
}

.logosPartnerContainer > ul{
    list-style: none;
}

.logoClient{
    width: 100%;
    max-width: 40%;
    filter: opacity(50%);
    transition: 0.4s;
    align-self: center;
}

.logoClient:hover{
    filter: opacity(1);
}

.logoPartner{
    margin: 15px;
    width: 100%;
    height: 75%;
    max-width: 10%;
    filter: opacity(50%);
    transition: 0.4s;
    align-self: center;
}

.logoPartner:hover{
    filter: opacity(1);
    cursor: pointer;
}

.listPartner{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-items: center;
}

.listPartner > li{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
    border: 1px solid #3333;
    box-shadow: 3px 3px 3px #3333;
    width: 100%;
    padding: 10px;
}

.listSystemText{
    list-style: '-';
    text-align: left;
}

.listSystemText > li{
    font-family: sans-serif;
    font-style: italic;
    margin-top: 15px;
    padding-left: 10px;
}

.width{
    max-width: 15%;
}

.form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 1%;
    margin-bottom: 10px;
}

.input{
    outline: none;
    height: 32px;
    font-family: Barlow-Bold;
    text-decoration: none;
    border: 1px solid #b2bec3;
    border-radius: 2px;
    padding: 1px;
    padding-left: 10px;
    margin: 3px;
    width: 100%;
    max-width: 70%;
}

.txtarea{
    outline: none;
    resize: none;
    height: 130px;
    text-decoration: none;
    border: 1px solid #3333;
    border-radius: 2px;
    padding: 4px;
    padding-left: 10px;
    margin: 3px;
    width: 100%;
    max-width: 70%;
    font-family: Barlow-Bold;
  }

.icon{
    margin-bottom: 10px;
}

.modalContainer{
    margin-top: 25vh;
    display: flex;
    flex-direction: column;
}

.modalText{
    text-align: center;
    font-family: Barlow-Bold;
    font-weight: bold;
}

.servicos{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 15px;
}

.servicos > ul{
    list-style: none;
    background-color: #0097e6;
    width: 25%;
    padding: 5px;
    height: 300px;
    color: #f2f2f2;
}

.video{
    width: 65%;
    height: 480px;
    align-self: center;
    margin-top: 20px;
}

@media only screen and (max-width: 540px) {
    .content{
        width: 100%;
        margin-left: 2.5%;
    }

    .video{
        width: 100%;
    }

    .contentPartner{
        width: 100%;
        margin-left: 2.5%;
        height: 150vh;
    }

    .contentClientLogo{
        height: 75vh;
    }

    .background{
        display: none;
    }

    .contentContainer{
        display: grid;
        grid-template-columns: auto;
    }

    .input{
        max-width: 100%;
    }

    .txtarea{
        max-width: 100%;
    }

    .logoClient{
        max-width: 80%;
        padding: 1rem;
    }

    .logoPartner{
        max-width: 16%;
        height: 65%;
    }

    .servicos > ul{
        align-items: center;
        font-size: 8px;
    }

    .contentMidleLine{
        border-right: 0px;
    }

}

.Container{
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    width: 100%;
    height: 100%;
}

.mgTop{
    margin-top: 50px;
}

.StartContent{  
    background-color: #F2F2F2;
    padding: 5rem;
    width: 100%;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #F2F2F2;
    background-image: url('../content/images/maopainel.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.StartContentCarrer{  
    background-color: #F2F2F2;
    padding: 1rem;
    width: 100%;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #F2F2F2;
    background-image: url('../content/images/closeup-of-a-worker-using-automated-machine-at-woodworking-production-facility.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    overflow: hidden;
}

.text{
    font-family: Barlow-Bold;
    font-weight: bold;
    font-size: 38px;
    text-align: start;
    padding: 1px;
}

.titleCarroussel{
    font-family: Barlow-Bold;
    font-weight: bold;
    font-size: 48px;
    text-align: start;
    padding: 1px;
}

.jobContent{
    background-color: #f2f2f2;
    border-bottom: 1px solid #3333;
    padding-top: 2rem;
    padding-left: 1rem;
    width: 100%;
    height: 55vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.jobList{
    width: 90%;
}

.jobList > li{
    list-style: none;
    border-bottom: 1px solid #3333;
    margin-bottom: 5%;
    margin-top: 2%;
    text-align: start;
}

.jobList > li > h2{
    transition: 0.5s;
    font-size: 24px;
    padding: 0;
    margin-bottom: 15px;
}

.jobList > li > h2:hover{
    cursor: pointer;
    padding-left: 2%;
}

.jobText{
    font-family: sans-serif;
    margin-top: 5px;
    font-size: 16px;
}

@media only screen and (max-width: 540px) {
    .Container{
        width: 95vw;
    }

    .StartContent{
        background-image: url('../content/images/mao_smartphone.bmp');
        width: 135vw;
        height: 35vh;
        padding: 1rem;
    }

    .StartContentCarrer{
        width: 115%;
        background-attachment: fixed;
    }
  }

.Navbar{
    top: 0;
    right: 0;
    left: 0;
    position: fixed;
    background-color: transparent;
    height: 70px;
    transition: 0.5s;
    z-index: 2;
}

.hamburguerNavBar{
    background-color: #f2f2f2;
    right: 0;
    left: 0;
    position: fixed;
    width: 100%;
    transition: 0.5s;
    box-shadow: 3px 3px #3333;
    z-index: -1;
    transition: 1s;
    margin-top: 48%;
}

.NavbarColor{
    background-color: #f2f2f2;
    top: 0;
    right: 0;
    left: 0;
    position: fixed;
    height: 70px;
    transition: 0.5s;
    box-shadow: 6px 6px 6px #3333;
    z-index: 2;
}

.ListStyle{
    display: flex;
    flex-direction: row;
    list-style: none;
    height: 100%;
    justify-content: space-evenly;
    align-items: center;
}

.ListStyle > li > a{
    font-weight: bold;
    font-family: Barlow-Bold;
    font-size: 18px;
    text-decoration: none;
    color: #000;
}

.hamburguerList {
    display: flex;
    flex-direction: column;
    list-style: none;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 1rem;
}

.hamburguerList > li > a{
    font-weight: bold;
    font-family: Barlow-Bold;
    text-decoration: none;
    color: #000;
    font-size: 18px;
}

.linkStyle{
    font-size: 18px;
}

.LogoStyle{
    width: 100%;
    max-width: 70px;
    position: fixed;
    margin: 10px;
    top: 0;
    cursor: pointer;
}

.textNav{
    margin-left: 30%;
}

@media only screen and (max-width: 540px) {
    .ListStyle{
        justify-content: center;
    }

    .linkStyle{
        font-size: 18px;
        margin: 2px;
    }

    .listMgTop{
        margin: 2px;
    }

    .LogoStyle{
        display: none;
    }
}